<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="privacypolicy"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    <main class="main-wrap">
      <div class="content-wrap">
        
        <!-- Intro -->
        <section style="padding-top: 47px;"
          id="services"
          class="intro bg-white"
        >
          <div class="container">
            <div class="text-left terms">
              <h1 class="privacy__title text-center mt-24 mb-16">
                Terms and Conditions of Business
              </h1>
              <p>
                <ol>
                  <li>
                    <strong>The Contract</strong> – A Contract for an assignment is made and an order is accepted 
                    subject to these terms and conditions of business. All other conditions are 
                    hereby excluded unless expressly accepted in writing by Heaward Sports Ltd. 
                    which also trades under the trading name of ‘Ignite Facilities’ (‘the 
                    Contractor’) before the commencement of business.
                  </li>
                  <li>
                    <strong>Agreement of Terms</strong> – This Contract supersedes any previous agreements and correspondence with the Customer relating to any work ordered.
                  </li>
                  <li>
                    <strong>Provision of Services</strong> – The Contractor or as necessary selected Associates 
                    will provide the services (“Services”) as agreed in writing with the Customer. 
                    If the Customer requests changes to the scope of the Services, the impact will 
                    be determined by the parties and any change in scope and associated costs will 
                    be agreed in writing before implementation. For all fundraising activity undertaken 
                    under point 4c below the client will be required to check and accept the contractors 
                    work prior to any applications/bids being submitted or other campaigns going live.
                  </li>
                  <li>
                    <strong>Scope of Services</strong>: The Contractor will provide one or more of the 
                    following Services to Customers as agreed through their acceptance of the Contractors 
                    written quotation:
                    <ul>
                      <li>
                       Preparation of bespoke Funding Reports which research grant funding prospects and 
                       advise the Customer on which potential funders are likely to offer opportunities 
                       that fit their organisational status, position and funding requirements.
                      </li>
                      <li>
                        Assisting Customers to become ‘Bid Ready’ by working with their leadership, 
                        volunteers and/or staff to interpret guidelines and gather/prepare the 
                        material and information necessary for a strong bid to be prepared.
                      </li>
                      <li>
                        Delivery of fundraising services including the preparation of grant funding 
                        applications, campaigns to seek funding from sponsorship and Corporate Social 
                        Responsibility based investments, crowdfunding campaigns or applications to 
                        secure social investment as requested in time to meet potential funders 
                        submission deadlines and prepare the proposal for submission.
                      </li>
                      <li>
                        Attending meetings necessary to accomplish the required work.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Provision of information by the Customer</strong> – The Contractor’s 
                    performance of the Services is dependent on the Customer providing such 
                    information and assistance as the Contractor may reasonably require in a 
                    timely manner. Any Services provided by the Contractor may be based upon 
                    information provided by the Customer and the Contractor therefore assumes 
                    no responsibility concerning the accuracy or completeness of any information 
                    provided by the Customer.
                  </li>
                  <li>
                    <strong>Contract Period and Deadlines</strong> – The period of assignment/engagement will be 
                    confirmed in writing by the contractor as part of all quotations provided and will be deemed to be agreed by the Customer upon acceptance of the quotation. This represents the minimum period of engagement by the Customer.
                  </li>
                  <li>
                    <strong>Fees and Terms of Payment</strong> – All invoices are payable fourteen days from the date of issue. All prices quoted are exclusive of VAT. 
                    Where a ‘Funding Report’ is commissioned the invoice for this is payable before work commences. For all other commissions, payment plans or subscriptions, an invoice schedule setting out the due dates when invoices will be raised or agreed staged payments or payment plan payments fall due will be agreed in writing with the Customer in advance of the Contractors acceptance of an order.
                  </li>
                  <li>
                    <strong>Expenses</strong> - Any mileage necessary to the delivery of the agreed scope of work will be charged at the rate of £0.45 per mile. Where travel essential to the delivery of the 
                    agreed scope of work is undertaken using public transport these costs will be charged to the Customer at cost. Where other costs are to be incurred by the contractor on the Customer’s behalf these will be recharged at cost, these will, however, be agreed with the customer in writing in advance of expenditure being made. 
                  </li>
                  <li>
                    <strong>Late Payment</strong> – The Contractor reserves the right to charge statutory interest at 8% above the prevailing Bank of England base rate 
                    per month in the case of overdue accounts. The Contractor may terminate the assignment with the Customer if the payment of an invoice is delayed.
                  </li>
                  <li>
                    <strong>Payment of Costs Associated with Recovery of Debt</strong> – All costs incurred by the Contractor in recovering the outstanding debt will be a charge to the Customer.
                  </li>
                  <li>
                    <strong>Immediate Suspension of Contract</strong> - At any time during the term of the Contract, either party may give immediate notice to the other suspending the performance of its duties and obligations under the Contract if: -
                    <ol>
                      <li>
                        circumstances exist or arise which, in the reasonable opinion of that party, materially or adversely affect the performance of, or the ability to perform, that party’s duties and obligations under the Contract; or
                      </li>
                      <li>
                        either party becomes aware that the other party has failed to disclose to it information which in the reasonable opinion of that party is material to the performance of its duties and obligations under the Contract; or
                      </li>
                      <li>
                        the Contractor has not received payment from the Customer of any invoice within 14 days of the invoice date
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Termination Following Suspension</strong> – the Contractor may terminate the Contract forthwith by notice in writing to the Customer if the period of suspension of the Contract referred to in clause 10 above exceeds 14 days. 
                  </li>
                  <li>
                    <strong>Actions Following Termination</strong> – Upon termination of the Contract, the Customer shall pay forthwith upon request all fees and expenses due in respect of the Services provided up to the date of termination, together with sums due for the completed assignment unless termination is due to the Contractor’s breach.
                  </li>
                  <li>
                    <strong>Accrued Rights</strong> – Termination of the Contract shall be without prejudice to any accrued rights of both parties.
                  </li>
                  <li>
                    <strong>Force Majeure</strong> - The Contractor shall not be deemed to be in breach of the Contract or liable to the Customer for any delay or failure to proceed with the assignment for reasons which are beyond the Contractors’ reasonable control. Such circumstances include.
                    <ol>
                      <li>
                        Act of God, explosion, flood, tempest, fire, or accident.
                      </li>                
                      <li>
                        War or threat of war, sabotage, insurrection, civil disturbance, or requisition.
                      </li>
                      <li>
                        Acts, restrictions, by-laws, prohibitions, or measure of any kind on the part of any governmental, parliamentary, or local authority.
                      </li>
                      <li>
                        Strikes, lock-outs, or other industrial actions or trade disputes.
                      </li>
                    </ol>

                  </li>
                  <li>
                    <strong>Limitation of Liability</strong> – The Contractor offers no guarantee regarding the success of any fundraising activity undertaken on behalf of the Customer. The Contractor’s liability to pay damages for all losses, 
                    including consequential damages, economic loss or failure to realise anticipated profits, savings or other benefits, incurred by the Customer as a direct result of a breach of contract or negligence or any other sort by the Contractor in connection with or arising out of the provision of the Services shall be limited to that proportion only of the Customer’s actual loss which was directly and solely caused by the Contractor and in any event, shall be limited to the value of any fees paid under this Contract.
                  </li>
                  <li>
                    <strong>Notices</strong> – Notices must be served either personally, sent by email, prepaid registered post or faxed to the address of the other party, or any other address as the parties may have notified during the period of the Contract, any notice sent by post will be deemed to have been delivered 48 hours after sending. Any notice sent by email, fax or served personally will be deemed to have been delivered on the first working day after its despatch.
                  </li>
                  <li>
                   <strong>Applicable Law</strong> – These conditions of business shall be governed and construed in accordance 
                   with English law and all disputes arising in connection therewith shall be submitted to the jurisdiction of the English courts.
                  </li>
                  <li>
                    <strong>Ownership</strong> - The Contractor will not obtain rights in any data, materials, or systems otherwise utilised or provided by Customer in connection with this agreement. At the request and expense of Customer, the Contractor will do all such things and sign all documents or instruments reasonably necessary in the opinion of Customer to 
                    enable the Customer to obtain, defend and enforce its rights in any such data, materials or systems. Upon request by the Customer and in any event promptly deliver to the Customer copies of such data, materials or systems that may be in the possession, custody or control of the Contractor. All Intellectual Property Rights in and to any software, documentation, drawings, data, information, database or product created or produced by the Contractor in performing the Services under this Agreement will be the property of Customer. The Contractor hereby assigns to the Customer absolutely its whole right, title and interest, present and future in and to such Intellectual Property Rights free from all liens, charges and encumbrances. The Contractor will provide the Customer with all information which the Customer may reasonably request to allow Customer to fully exercise its proprietary rights in any jurisdiction. The Contractor will at the Customer’s request and expense undertake and execute all acts, deeds, documents and steps necessary to effectively vest such Intellectual Property Rights in the Customer. The provisions and requirements of this clause will survive the expiration or termination of this Agreement.
                  </li>
                  <li>
                    <strong>Confidentiality</strong> - Both parties agree to adhere to generally accepted confidentiality practices and to provide each other with their best efforts in fulfilment of this contract. Contractor agrees not to disclose private information about the organisation or its donors. “Private information” does not include information available in the public domain.
                  </li>
                  <li>
                    <strong>Privacy and GDPR</strong> - The Contractor will operate within the bounds of the companies Privacy Policy published on the www.heawardsolutions.co.uk website. The Customer is required to ensure that any data passed to the Contractor is done so under both this policy and the terms of their own privacy policy.
                  </li>
                  <li>
                    <strong>Subscription based products</strong> - We offer a range of products on a subscription basis. A 12-month minimum contract term applies to all of these commencing from the date of the initial payment. During this period, it is possible to upgrade to a higher 
                    level product but not downgrade. Upgrading will result in a new twelve-month minimum term commencing from the date of the upgrade. All subscription products must be paid for via direct debit (provided for us by Go Cardless). Where a customer who has an active subscription then commissions any work outside of that subscription, we will credit the value of one month’s subscription payment against the cost of the additional services ordered (this excludes subscription upgrades).
                  </li>
                </ol>
              </p>
            </div>
          </div>
        </section>
      </div>
    </main>
  </body>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  metaInfo: {
    title: 'Terms and Conditions',
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Terms and Conditions | Ignite Facilities'
      }
    ]
  },
  components: {
  }
};
</script>

<style lang="scss">
.training {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}
</style>
